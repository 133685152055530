import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AppSidebarService {
  private itemsSource = new Subject<MenuItem[]>();
  private lastItems;

  itemsHandler = this.itemsSource.asObservable();

  setItems(items: MenuItem[]) {
    this.itemsSource.next(items);
    this.lastItems = items;
  }

  getItems() {
    return this.lastItems;
  }
}

export const SIDEBARITEMS = [
  {
    label: 'commons.main',
    items: [
      {
        label: 'breadcrumb.dashboard',
        icon: 'pi fas fa-house',
        routerLink: ['dashboard'],
        preventExact: 'true',
        permissions: undefined,
      },
    ],
  },
  {
    label: 'breadcrumb.users',
    permissions: ['patient', 'subscription', 'contact', 'promocode'],
    routerLink: [''],
    items: [
      {
        label: 'breadcrumb.subscripers',
        icon: 'pi fas fa-users-between-lines',
        routerLink: ['subscriptions/subscribers'],
        preventExact: 'true',
        permissions: ['subscription'],
      },
      {
        label: 'breadcrumb.subscripersFollow',
        icon: 'pi fas fa-user-clock',
        routerLink: ['subscriptions/follow'],
        preventExact: 'true',
        permissions: ['subscription'],
      },
      {
        label: 'breadcrumb.subscripersRenew',
        icon: 'pi fas fa-user-plus',
        routerLink: ['subscriptions/renew'],
        preventExact: 'true',
        permissions: ['subscription'],
      },
      {
        label: 'breadcrumb.plans',
        icon: 'pi fas fa-money-check-dollar',
        routerLink: ['plans'],
        preventExact: 'true',
        permissions: ['subscription'],
      },
      {
        label: 'breadcrumb.contacts',
        icon: 'pi fas fa-phone',
        routerLink: ['contacts'],
        preventExact: 'true',
        permissions: ['contact'],
      },
      {
        label: 'breadcrumb.promocodes',
        icon: 'pi fas fa-hand-holding-dollar',
        routerLink: ['promocodes'],
        preventExact: 'true',
        permissions: ['promocode'],
      },
      {
        label: 'breadcrumb.users',
        icon: 'pi fas fa-users',
        routerLink: ['users'],
        preventExact: 'true',
        permissions: ['patient'],
      },
    ],
  },
  {
    label: 'breadcrumb.providers',
    permissions: ['provider', 'doctor', 'contact'],
    routerLink: ['providers'],
    items: [
      {
        label: 'providers.doctorsclinics',
        icon: 'pi fas fa-stethoscope',
        routerLink: ['providers/clinics'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.centers',
        icon: 'pi fas fa-staff-snake',
        routerLink: ['providers/centers'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.hospitals',
        icon: 'pi fas fa-square-h',
        routerLink: ['providers/hospitals'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.labs',
        icon: 'pi fas fa-flask',
        routerLink: ['providers/labs'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.scans',
        icon: 'pi fas fa-radiation',
        routerLink: ['providers/scans'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'breadcrumb.services',
        icon: 'pi fas fa-wrench',
        routerLink: ['providers/services'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.followups',
        icon: 'pi fas fa-square-envelope',
        routerLink: ['providers/followups'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.renews',
        icon: 'pi fas fa-square-plus',
        routerLink: ['providers/renews'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'providers.frozens',
        icon: 'pi fas fa-square-person-confined',
        routerLink: ['providers/frozens'],
        preventExact: 'true',
        permissions: ['provider'],
      },
      {
        label: 'breadcrumb.doctors',
        icon: 'pi fas fa-user-doctor',
        routerLink: ['doctors'],
        preventExact: 'true',
        permissions: ['doctor'],
      },
      {
        label: 'breadcrumb.contactsProvider',
        icon: 'pi fas fa-square-phone',
        routerLink: ['contacts-provider'],
        preventExact: 'true',
        permissions: ['contact'],
      },
      {
        label: 'breadcrumb.codes',
        icon: 'pi fas fa-qrcode',
        routerLink: ['codes'],
        preventExact: 'true',
      },
    ],
  },
  {
    label: 'breadcrumb.employees',
    permissions: ['employee', 'role'],
    items: [
      {
        label: 'breadcrumb.employees',
        icon: 'pi fas fa-briefcase',
        routerLink: ['employees'],
        preventExact: 'true',
        permissions: ['employee'],
      },
      {
        label: 'breadcrumb.roles',
        icon: 'pi fas fa-sitemap',
        routerLink: ['roles'],
        preventExact: 'true',
        permissions: ['role'],
      },
    ],
  },
  {
    label: 'breadcrumb.system',
    permissions: ['specialty', 'subspecialty', 'government', 'area', 'disease'],
    items: [
      {
        label: 'breadcrumb.specialities',
        icon: 'pi fas fa-table-cells-large',
        routerLink: ['specialities'],
        preventExact: 'true',
        permissions: ['specialty'],
      },
      {
        label: 'breadcrumb.subSpecialities',
        icon: 'pi fas fa-folder-tree',
        routerLink: ['subspecialities'],
        preventExact: 'true',
        permissions: ['subspecialty'],
      },

      {
        label: 'breadcrumb.governorates',
        icon: 'pi fas fa-map-location-dot',
        routerLink: ['governorates'],
        preventExact: 'true',
        permissions: ['government'],
      },
      {
        label: 'breadcrumb.areas',
        icon: 'pi fas fa-location-dot',
        routerLink: ['areas'],
        preventExact: 'true',
        permissions: ['area'],
      },

      {
        label: 'breadcrumb.diseases',
        icon: 'pi fas fa-disease',
        routerLink: ['diseases'],
        preventExact: 'true',
        permissions: ['disease'],
      },
    ],
  },
];

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../services/localstorage.service';
import { RolesService } from '../services/roles.service';
import { firstValueFrom } from 'rxjs';
import { AppSidebarService, SIDEBARITEMS } from '../services/app.sidebar.service';
import { AppGovsFilterService } from '../services/app.govs-filter.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGaurdService {
  private sidebarItems: any[];
  private myPermissions: string[];
  private myGovernorates;

  constructor(
    private router: Router,
    private localstorage: LocalStorageService,
    private rolesService: RolesService,
    private sidebarService: AppSidebarService,
    private appGovsFilter: AppGovsFilterService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.localstorage.getToken();
    if (!token) {
      this.sidebarItems = null;
      this.myPermissions = null;
      this.myGovernorates = null;
      if (!state.url.includes('login') && !state.url.includes('reset')) {
        return this.router.parseUrl('login');
      } else {
        return true;
      }
    }
    if (!this.myGovernorates) {
      this.getGovs();
    }
    if (!this.sidebarItems) {
      return this.getMyPermissions().then(() => {
        this.buildSidebarItems();
        return this.accessbilityCheck(state);
      });
    } else {
      return this.accessbilityCheck(state);
    }
  }

  buildSidebarItems() {
    this.sidebarItems = SIDEBARITEMS.filter(
      (item) =>
        !item.permissions ||
        this.myPermissions.some((permission) => item.permissions.includes(permission.split('_')[0]))
    ).map((item) => ({
      ...item,
      items: (item.items as any[])?.filter(
        (childItem) =>
          !childItem.permissions ||
          this.myPermissions.some((permission) =>
            childItem.permissions?.includes(permission.split('_')[0])
          )
      ),
    }));
    this.sidebarService.setItems([...this.sidebarItems]);
  }

  accessbilityCheck(state: RouterStateSnapshot) {
    if (state.url === '/' && this.sidebarItems?.length) {
      return this.router.parseUrl(this.sidebarItems[0].items[0].routerLink[0]);
    } else if (
      this.sidebarItems.some(
        (item) =>
          (item.routerLink && state.url.includes(item.routerLink[0])) ||
          item.items.some((childItem) => state.url.includes(childItem.routerLink[0]))
      )
    ) {
      return true;
    } else {
      return this.router.parseUrl('access');
    }
  }

  private async getMyPermissions() {
    if (!this.myPermissions) {
      this.myPermissions = (
        await firstValueFrom(this.rolesService.getMyPermissions())
      ).data.permissions;
      return this.myPermissions;
    } else {
      return this.myPermissions;
    }
  }

  async isAuthorized(requiredPermissions: string[], requireAll = true): Promise<boolean> {
    return await this.getMyPermissions()
      .then((permissions) => {
        if (requireAll) {
          return requiredPermissions.every((permission) => permissions.includes(permission));
        } else {
          return requiredPermissions.some((permission) => permissions.includes(permission));
        }
      })
      .catch((err) => false);
  }
  async getGovs() {
    this.myGovernorates = await this.appGovsFilter.getGovs();
  }
}

<div
  class="layout-sidebar"
  [ngClass]="{
    'layout-sidebar-active': appMain.sidebarActive,
    'layout-sidebar-dark': config.darkMenu
  }"
  (click)="appMain.onSidebarClick($event)"
  (mouseover)="appMain.sidebarActive = !appMain.isMobile()"
  (mouseleave)="appMain.sidebarActive = false"
>
  <div class="sidebar-logo">
    <a routerLink="providers">
      <img alt="logo" src="assets/layout/images/logo-slim.png" />
      <span class="app-name">Espitalia</span>
    </a>
    <button
      class="sidebar-anchor cursor-pointer"
      title="Toggle Menu"
      (click)="appMain.onToggleMenuClick($event)"
    ></button>
  </div>

  <ul class="layout-menu" (click)="onMenuClick()">
    <ng-container *ngFor="let item of menuItems; let i = index">
      <li
        app-menuitem
        *ngIf="!item.separator && item.visible !== false"
        [translate]="item.label"
        [item]="item"
        [index]="i"
        [root]="true"
      ></li>
    </ng-container>
  </ul>
</div>

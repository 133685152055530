<div class="layout-topbar">
  <a routerLink="/" *ngIf="appMain.isHorizontal() && !appMain.isMobile()" class="topbar-logo">
    <img alt="logo" src="assets/layout/images/logo-slim.png" />
    <span class="app-name">ESPITALIA</span>
  </a>

  <img alt="logo" src="assets/layout/images/logo-slim.png" class="mobile-logo" />

  <a href="#" class="menu-btn" (click)="appMain.onMenuButtonClick($event)">
    <i class="pi pi-bars"></i>
  </a>

  <a href="#" class="topbar-menu-btn" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
    <i class="pi pi-user"></i>
  </a>

  <div class="layout-topbar-menu-wrapper">
    <ul
      class="topbar-menu fadeInDown"
      [ngClass]="{ 'topbar-menu-active': appMain.topbarMenuActive }"
      (click)="appMain.onTopbarMenuClick($event)"
    >
      <li>
        <div class="formgroup-inline">
          <div class="field-togglebutton">
            <p-button
              name="layoutColor"
              [icon]="currentMode === 'light' ? 'pi pi-moon' : 'pi pi-sun'"
              (click)="changeLayoutMode()"
            ></p-button>
          </div>
        </div>
      </li>
      <li>
        <p-button
          class="mx-2"
          (click)="changeLang()"
          [label]="translate.currentLang === 'ar' ? 'English' : 'العربية'"
          icon="pi pi-globe"
        >
        </p-button>
      </li>
      <li>
        <p-multiSelect
          class="gov"
          [style]="{ minWidth: '200px', width: '200px' }"
          [placeholder]="'breadcrumb.governorates' | translate"
          [emptyMessage]="'commons.noResult' | translate"
          [emptyFilterMessage]="'commons.noResult' | translate"
          [styleClass]="'bg-primary'"
          [optionLabel]="translate.currentLang === 'en' ? 'name.en' : 'name.ar'"
          [options]="governorates"
          [ngModel]="selectedGovs"
          (ngModelChange)="changeGovs($event)"
        ></p-multiSelect>
      </li>
    </ul>
  </div>
</div>

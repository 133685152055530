import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { NgForm } from '@angular/forms';
import { NotifyService } from 'src/app/modules/shared/services/notify.service';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './app.reset.component.html',
})
export class AppResetComponent implements OnInit {
  constructor(
    private notifyService: NotifyService,
    private authService: AuthService,
    private router: Router
  ) {}

  isLoading = false;
  submitted = false;
  reset: { password: string; password_confirmation: string } = {
    password: '',
    password_confirmation: '',
  };
  token: string;

  ngOnInit(): void {
    this.token = this.router.url.split('/').slice(-1)[0];
  }

  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid || this.reset.password_confirmation !== this.reset.password || !this.token) {
      return;
    }
    this.isLoading = true;
    this.authService
      .resetpwd(this.reset, this.token)
      .pipe(take(1))
      .subscribe((res) => {
        this.notifyService.success(res.message);
        this.router.navigate(['/login']);
      })
      .add(() => {
        this.isLoading = false;
      });
  }
}

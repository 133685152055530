import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { LocalStorageService } from './localstorage.service';
import { Governorate } from '../models/governorate';
import { Area } from '../models/area';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppGovsFilterService {
  // url = environment.urlDomain + 'governments';
  url = environment.urlDomain + 'employee/governorates';
  constructor(private localStorage: LocalStorageService, private http: HttpClient) {}

  private govsSource = new Subject<Partial<Governorate>[]>();
  private areaSource = new Subject<Partial<Area>[]>();
  private allgovsSource = new Subject<Partial<Governorate>[]>();

  private selectedGovs: Partial<Governorate>[];
  selectedGovsIds: string;
  private allgovs: Partial<Governorate>[];
  private selectedAreas: Partial<Area>[];

  govsHandler = this.govsSource.asObservable();
  areasHandler = this.areaSource.asObservable();
  allgovsHandler = this.allgovsSource.asObservable();

  setSelectedGovs(govs: Partial<Governorate>[]) {
    this.govsSource.next(govs);
    this.selectedGovs = govs;
    this.selectedGovsIds = this.selectedGovs.map((gov) => gov.id).join(',');
    this.setSelectedAreas(
      this.selectedGovs?.length
        ? this.selectedGovs?.reduce((areas: Partial<Area>[], gov) => [...areas, ...gov.areas], [])
        : this.allgovs?.reduce((areas: Partial<Area>[], gov) => [...areas, ...gov.areas], [])
    );
    this.localStorage.setGovs(this.selectedGovsIds);
  }

  setSelectedAreas(areas: Partial<Area>[]) {
    this.areaSource.next(areas);
    this.selectedAreas = areas;
  }

  setAllGovs(govs: Partial<Governorate>[]) {
    this.allgovsSource.next(govs);
    this.allgovs = govs;
  }

  getSelectedAreas() {
    return this.selectedAreas;
  }
  getSelectedGovs() {
    return this.selectedGovs;
  }
  getAllGovs() {
    return this.allgovs;
  }
  async getGovs() {
    this.setAllGovs((await firstValueFrom(this.getGovernorates()))?.data);
    this.setSelectedGovs(
      this.allgovs?.filter((gov) =>
        this.localStorage
          .getGovs()
          ?.split(',')
          .some((selected) => +selected === gov.id)
      ) ?? []
    );
    return this.allgovs;
  }

  changeGovs(event: Partial<Governorate>[]) {
    this.setSelectedGovs(event);
  }

  getGovernorates(): Observable<{
    message: string;
    data: Governorate[];
  }> {
    return this.http.get<{
      message: string;
      data: Governorate[];
    }>(this.url);
  }
}

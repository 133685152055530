import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { NgForm } from '@angular/forms';
import { NotifyService } from 'src/app/modules/shared/services/notify.service';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-email',
  templateUrl: './app.reset-email.component.html',
})
export class AppResetEmailComponent implements OnInit {
  constructor(private notifyService: NotifyService, private authService: AuthService) {}

  isLoading = false;
  submitted = false;
  email: string;
  validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  ngOnInit(): void {}

  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this.isLoading = true;
    this.authService
      .resetemail(this.email)
      .pipe(take(1))
      .subscribe((res) => {
        this.notifyService.success(res.message);
      })
      .add(() => {
        this.isLoading = false;
      });
  }
}

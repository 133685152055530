import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class ModeService {
  private defaultMode: 'light' | 'dark' = 'light';

  constructor(private localStorageService: LocalStorageService) {}

  getCurrentMode(): 'light' | 'dark' {
    return (this.localStorageService.getMode() as 'light' | 'dark') ?? this.defaultMode;
  }

  setCurrentMode(mode) {
    this.localStorageService.setMode(mode);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from '../app.main.component';
import { ConfigService } from 'src/app/modules/shared/services/app.config.service';
import { AppConfig } from 'src/app/modules/shared/models/appconfig';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppSidebarService } from 'src/app/modules/shared/services/app.sidebar.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {
  menuItems: any[];
  config: AppConfig;
  destroyed = new Subject<void>();

  constructor(
    public appMain: AppMainComponent,
    public configService: ConfigService,
    public translate: TranslateService,
    private sidebarService: AppSidebarService
  ) {
    this.config = this.configService.config;
    this.configService.configUpdate$.pipe(takeUntil(this.destroyed)).subscribe((config) => {
      this.config = config;
    });
    this.sidebarService.itemsHandler.pipe(takeUntil(this.destroyed)).subscribe((response) => {
      this.menuItems = this.sidebarService.getItems();
    });
  }

  ngOnInit() {
    // this.menuItems = [{ label: 'Espitalia', items: this.sidebarService.getItems() }];
    this.menuItems = this.sidebarService.getItems();
  }

  onMenuClick() {
    this.appMain.menuClick = true;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

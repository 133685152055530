import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLoginComponent } from './login/app.login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from '../shared/services/auth.service';
import { AuthGaurdService } from '../shared/guards/auth.guard';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { AppResetComponent } from './reset/app.reset.component';
import { PasswordModule } from 'primeng/password';
import { AppResetEmailComponent } from './reset-email/app.reset-email.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [AppLoginComponent, AppResetComponent, AppResetEmailComponent],
  providers: [AuthService, AuthGaurdService, AuthInterceptor],
})
export class AuthModule {}

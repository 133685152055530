import { LocalStorageService } from '../../shared/services/localstorage.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { NotifyService } from '../../shared/services/notify.service';
import { User } from '../../shared/models/user';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  isSubmitted = false;
  isLoading = false;
  authError = false;
  authMessage = 'Email or Password are wrong';

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private notifyService: NotifyService,
    private localstorage: LocalStorageService,
    private router: Router,
    public translate: TranslateService,
    public app: AppComponent
  ) {}

  private _initLoginForm() {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    this.loginFormGroup.markAllAsTouched();
    if (this.loginFormGroup.invalid) {
      return;
    }
    this.isLoading = true;
    this.auth
      .login(this.loginForm.email.value, this.loginForm.password.value)
      .subscribe((res: any) => {
        this.notifyService.success(res.message);
        this.localstorage.setToken(res.data.token);
        this.localstorage.setEmail(res.data.user.email);
        this.router.navigate(['/']);
      })
      .add(() => (this.isLoading = false));
  }

  get loginForm() {
    return this.loginFormGroup.controls;
  }

  ngOnInit() {
    this._initLoginForm();
  }
}

<div
  class="layout-wrapper"
  (click)="onWrapperClick()"
  [ngClass]="{
    'layout-wrapper-static': config.layoutMode === 'static',
    'layout-wrapper-active': mobileMenuActive,
    'layout-menu-horizontal': config.layoutMode === 'horizontal',
    'layout-rtl': config.isRTL,
    'p-ripple-disabled': !config.ripple,
    'p-input-filled': config.inputStyle === 'filled'
  }"
>
  <app-menu></app-menu>
  <div class="layout-main">
    <app-topbar></app-topbar>

    <app-breadcrumb></app-breadcrumb>

    <div class="layout-footer layout-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
    <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
  </div>
</div>

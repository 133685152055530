<div class="login-body">
  <div class="login-panel p-fluid">
    <div class="login-panel-header">
      <img src="assets/layout/images/logo-slim.png" />
    </div>
    <div class="login-panel-content">
      <form #form="ngForm" (keyup.enter)="submit(form)">
        <div class="grid">
          <div class="col-12">
            <h1>Reset Password</h1>
          </div>

          <div class="col-12">
            <span>
              <label>Password</label>
              <p-password
                id="password"
                name="password"
                required
                [(ngModel)]="reset.password"
                [feedback]="false"
                [toggleMask]="true"
              ></p-password>
            </span>
            <small *ngIf="submitted && !reset.password" class="p-error">
              <span>Password is required</span>
            </small>
          </div>
          <div class="col-12">
            <span>
              <label>Confirm Password</label>
              <p-password
                id="password_confirmation"
                name="password_confirmation"
                required
                [(ngModel)]="reset.password_confirmation"
                [feedback]="false"
                [toggleMask]="true"
              ></p-password>
            </span>
            <small *ngIf="submitted && !reset.password_confirmation" class="p-error">
              password confirmation is required
            </small>
            <small
              *ngIf="
                submitted &&
                reset.password &&
                reset.password_confirmation &&
                reset.password !== reset.password_confirmation
              "
              class="p-error"
            >
              rpeated password should be identical to password
            </small>
          </div>
          <div class="col-12">
            <button
              pButton
              [loading]="isLoading"
              type="button"
              label="submit"
              icon="pi pi-check"
              (click)="submit(form)"
            ></button>
          </div>
          <div class="col-12" *ngIf="submitted">
            <small class="p-error"></small>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Governorate } from '../models/governorate';
import { Observable } from 'rxjs';
import { Params } from '../models/params';

@Injectable()
export class GovernoratesService {
  url = environment.urlDomain + 'governments';

  constructor(private http: HttpClient) {}

  getGovernorates(params?: Partial<Params>): Observable<{
    message: string;
    data: { governments: Governorate[]; records: number };
  }> {
    return this.http.get<{
      message: string;
      data: { governments: Governorate[]; records: number };
    }>(this.url, { params: params ? params : null });
  }

  getGovernorate(id) {
    return this.http.get<{ message: string; status: boolean; data: Governorate }>(
      this.url + `/${id}`
    );
  }

  updateGovernorates(governorate: any) {
    if (governorate.id) {
      return this.http.patch<{ message: string; status: boolean; data: Governorate }>(
        this.url + `/${governorate.id}`,
        governorate
      );
    } else {
      return this.http.post<{ message: string; status: boolean; data: Governorate }>(
        this.url,
        governorate
      );
    }
  }

  deleteGovernorate(id: number) {
    return this.http.delete<{ message: string; status: boolean; data: Governorate }>(
      this.url + `/${id}`
    );
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConfig } from '../models/appconfig';
import { LangService } from './lang.service';
import { ModeService } from './mode.service';

@Injectable()
export class ConfigService {
  private _config: AppConfig;

  constructor(private langService: LangService, private modeService: ModeService) {
    this._config = {
      theme: 'lara-light-indigo',
      inputStyle: 'filled',
      layoutMode: 'static',
      ripple: true,
      dark: this.modeService.getCurrentMode() === 'dark' ? true : false,
      darkMenu: this.modeService.getCurrentMode() === 'dark' ? true : false,
      layoutColor: this.modeService.getCurrentMode() === 'dark' ? 'dark' : 'light',
      isRTL: this.langService.getCurrentLang() === 'ar' ? true : false,
    };
  }

  get config(): AppConfig {
    return this._config;
  }

  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this._config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }
}

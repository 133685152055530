import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Role } from '../models/roles';
import { Params } from '../models/params';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  rolesUrl = environment.urlDomain + 'roles';
  permissionsUrl = environment.urlDomain + 'permissions';
  autPermissionsUrl = environment.urlDomain + 'user/permissions';

  constructor(private http: HttpClient) {}

  getPermissions(): Observable<{
    message: string;
    status: boolean;
    data: { permissions: string[] };
  }> {
    return this.http.get<{ message: string; status: boolean; data: { permissions: string[] } }>(
      this.permissionsUrl
    );
  }

  getMyPermissions(): Observable<{
    message: string;
    status: boolean;
    data: { permissions: string[] };
  }> {
    return this.http.get<{ message: string; status: boolean; data: { permissions: string[] } }>(
      this.autPermissionsUrl
    );
  }

  getRoles(
    params?: Partial<Params>
  ): Observable<{ message: string; status: boolean; data: { roles: Role[]; records: number } }> {
    return this.http.get<{
      message: string;
      status: boolean;
      data: { roles: Role[]; records: number };
    }>(this.rolesUrl, { params: params ? params : null });
  }

  getRole(id) {
    return this.http.get<{ message: string; status: boolean; data: Role }>(
      this.rolesUrl + `/${id}`
    );
  }

  updateRoles(role: any) {
    return this.http.post<{ message: string; status: boolean; data: Role }>(
      this.rolesUrl + (role.id ? `/${role.id}` : ''),
      { ...role, _method: role.id ? 'PATCH' : 'POST' }
    );
  }

  deleteRole(id: number) {
    return this.http.delete<{ message: string; status: boolean; data: Role }>(
      this.rolesUrl + `/${id}`
    );
  }
}

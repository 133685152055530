import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../modules/shared/services/app.menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { AppConfig } from '../modules/shared/models/appconfig';
import { Subject, Subscription } from 'rxjs';
import { ConfigService } from '../modules/shared/services/app.config.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Params } from '../modules/shared/models/params';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
})
export class AppMainComponent implements OnDestroy {
  menuClick: boolean;
  menuButtonClick: boolean;
  topbarMenuButtonClick: boolean;
  topbarMenuClick: boolean;
  topbarMenuActive: boolean;
  activeTopbarItem: Element;
  sidebarActive: boolean;
  mobileMenuActive: boolean;
  menuHoverActive: boolean;
  resetMenu: boolean;
  configActive: boolean;
  configClick: boolean;
  config: AppConfig;
  subscription: Subscription;

  constructor(
    private menuService: MenuService,
    public configService: ConfigService,
    private primengConfig: PrimeNGConfig,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
  }
  onWrapperClick() {
    if (!this.menuClick && !this.menuButtonClick) {
      this.mobileMenuActive = false;
    }

    if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
      this.topbarMenuActive = false;
      this.activeTopbarItem = null;
    }

    if (!this.menuClick) {
      if (this.isHorizontal()) {
        this.menuService.reset();
      }

      this.menuHoverActive = false;
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    this.configClick = false;
    this.menuClick = false;
    this.menuButtonClick = false;
    this.topbarMenuClick = false;
    this.topbarMenuButtonClick = false;
  }

  onMenuButtonClick(event: Event) {
    this.menuButtonClick = true;
    if (this.isMobile()) {
      this.mobileMenuActive = !this.mobileMenuActive;
    }
    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event: Event) {
    this.topbarMenuButtonClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    event.preventDefault();
  }

  onTopbarRootItemClick(event: Event, item: Element) {
    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarMenuClick(event: Event) {
    this.topbarMenuClick = true;
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onConfigClick(event) {
    this.configClick = true;
  }

  onRippleChange(event) {
    this.config.ripple = event.checked;
    this.primengConfig = event.checked;
  }

  onToggleMenuClick(event: Event) {
    this.config.layoutMode = this.config.layoutMode !== 'static' ? 'static' : 'overlay';
    event.preventDefault();
  }

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isHorizontal() {
    return this.config.layoutMode === 'horizontal';
  }

  isOverlay() {
    return this.config.layoutMode === 'overlay';
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

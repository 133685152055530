import { Injectable } from '@angular/core';

const TOKEN = 'jwtToken';
const LANG = 'currentLang';
const MODE = 'currentMode';
const EMAIL = 'email';
const GOVS = 'govs';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  currentLang: string;
  currentMode: 'light' | 'dark';

  constructor() {}

  setMode(mode) {
    return localStorage.setItem(MODE, mode);
  }

  getMode() {
    return localStorage.getItem(MODE);
  }

  setLang(lang) {
    return localStorage.setItem(LANG, lang);
  }

  getLang() {
    return localStorage.getItem(LANG);
  }

  setToken(data) {
    localStorage.setItem(TOKEN, data);
  }

  getToken() {
    return localStorage.getItem(TOKEN);
  }

  removeToken() {
    localStorage.removeItem(TOKEN);
  }

  setEmail(email: string) {
    localStorage.setItem(EMAIL, email);
  }

  getEmail() {
    return localStorage.getItem(EMAIL);
  }

  removeEmail() {
    localStorage.removeItem(EMAIL);
  }

  setGovs(data) {
    localStorage.setItem(GOVS, data);
  }

  getGovs() {
    return localStorage.getItem(GOVS);
  }

  removeGovs() {
    localStorage.removeItem(GOVS);
  }
}

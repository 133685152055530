import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './localstorage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginURL = environment.urlDomain + 'login';
  resetURL = environment.urlDomain + 'password/reset';

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorage: LocalStorageService
  ) {}

  login(email, password) {
    return this.http.post(this.loginURL, { email, password });
  }

  resetpwd(
    reset: { password: string; password_confirmation: string },
    urlToken
  ): Observable<{
    message: string;
    status: boolean;
  }> {
    return this.http.post<{ message: string; status: boolean }>(
      this.resetURL + '/' + urlToken,
      reset
    );
  }
  resetemail(email: string): Observable<{
    message: string;
    status: boolean;
  }> {
    return this.http.post<{ message: string; status: boolean }>(this.resetURL, { email });
  }
  logout() {
    this.localstorage.removeToken();
    this.localstorage.removeEmail();
    this.router.navigate(['login']);
  }
}

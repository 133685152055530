import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api/treenode';
import { TranslateService } from '@ngx-translate/core';
import { Role, TableRole } from '../models/roles';
import { Location } from '../models/location';

@Injectable()
export class NormalizeService {
  constructor(private translate: TranslateService) {}

  addURL<T extends ID>(data: T[], route: string, key?: string): (T & { url: string })[] {
    return data.map((entity) => ({ ...entity, url: route + (entity[key] ?? entity.id) }));
  }

  normalizeCommingPermissions(permissions: string[], parents: TreeNode[]): TreeNode[] {
    let results: TreeNode[] = [];
    parents.map((parent) => {
      permissions.map((permission) => {
        const selected = parent.children.find((child) => child.label === permission);
        if (selected) {
          results.push(selected);
        }
      });
      if (parent.children.every((child) => results.indexOf(child) !== -1)) {
        results.push(parent);
      } else if (parent.children.some((child) => results.indexOf(child) !== -1)) {
        parent.partialSelected = true;
      }
    });
    return results;
  }

  normalizePermissionsForCheckbox(permissions: string[]): TreeNode[] {
    let result = [];
    this.getEntities(permissions).map((entity) => {
      permissions.map((permission) => {
        if (permission.indexOf(entity) === 0) {
          const selectedEntityPermissions = result.find(
            (entityPermissions) => entityPermissions.label === entity
          );
          if (selectedEntityPermissions) {
            selectedEntityPermissions.children.push({
              label: permission,
              key: permission,
              parent: result.find((parent) => permission.includes(parent.label)),
            });
          } else {
            const parent = {
              label: entity,
              key: entity + 'parent',
              children: [],
            };
            parent.children.push({
              label: permission,
              key: permission,
              parent,
            });
            result.push(parent);
          }
        }
      });
    });
    return result;
  }

  normalizePermissionsForServer(permissions: TreeNode[]): string[] {
    let result: string[] = [];
    permissions.map((permission) => {
      if (!permission.children) {
        result.push(permission.label);
      }
    });
    return result;
  }

  normalizeRolesForTable(roles: Role[], route?: string): TableRole[] {
    return roles.map((roles) => ({
      id: roles.id,
      name: roles.name,
      url: route ? route + '/' + roles.id : null,
    }));
  }

  normalizeProviderDoctorForTable(
    doctorLocations: Partial<Location>[],
    url?: string
  ): Partial<Location>[] {
    return doctorLocations.map((doctorLocation) => ({
      ...doctorLocation,
      url: url ? url + doctorLocation.id : null,
    }));
  }

  normalizeProviderDoctorsForTable(
    doctorLocations: Partial<Location>[],
    url?: string
  ): Partial<Location>[] {
    return doctorLocations.map((doctorLocation) => ({
      ...doctorLocation,
      url: `/providers/${doctorLocation.provider.id}/location/${doctorLocation.id}`,
    }));
  }

  getNormalizedDoctorLocation(
    doctorsLocation: Partial<Location>[],
    url?: string
  ): Partial<Location>[] {
    return doctorsLocation.map((doctorLocation) => ({
      ...doctorLocation,
      url: url ? url + doctorLocation.id : null,
    }));
  }

  daysToString(allDays: number): string {
    let output = '';
    const duration = {
      years: Math.floor(allDays / 365),
      months: Math.floor((allDays % 365) / 30),
      days: (allDays % 365) % 30,
    };
    for (let key in duration) {
      if (duration.hasOwnProperty(key)) {
        if (duration[key]) {
          output += duration[key] + ' ' + this.translate.instant(`commons.${key}`) + ' ';
        }
      }
    }
    return output;
  }

  getEntities(permissions: string[]): string[] {
    let entities = [];
    permissions?.forEach((permission) => {
      if (!entities.includes(permission.split('_')[0])) {
        entities.push(permission.split('_')[0]);
      }
    });
    return entities;
  }
}

export interface ID {
  id?: number;
}

<div class="grid dashboard">
  <div class="col-12 md:col-3">
    <div class="overview-box overview-box-1">
      <div class="overview-box-header"></div>
      <div class="overview-box-content">
        <div class="overview-box-icon"><i class="pi pi-check-circle"></i></div>
        <div class="overview-box-title">{{ subscripers.name[lang] }}</div>
        <div class="overview-box-count">{{ subscripers.value }}</div>
      </div>
    </div>
  </div>
  <div class="col-12 md:col-3">
    <div class="overview-box overview-box-2">
      <div class="overview-box-header"></div>
      <div class="overview-box-content">
        <div class="overview-box-icon"><i class="pi pi-users"></i></div>
        <div class="overview-box-title">{{ providers.name[lang] }}</div>
        <div class="overview-box-count">{{ providers.value }}</div>
      </div>
    </div>
  </div>
  <div class="col-12 md:col-3">
    <div class="overview-box overview-box-3">
      <div class="overview-box-header"></div>
      <div class="overview-box-content">
        <div class="overview-box-icon"><i class="pi pi-heart"></i></div>
        <div class="overview-box-title">{{ doctors.name[lang] }}</div>
        <div class="overview-box-count">{{ doctors.value }}</div>
      </div>
    </div>
  </div>
  <div class="col-12 md:col-3">
    <div class="overview-box overview-box-4">
      <div class="overview-box-header"></div>
      <div class="overview-box-content">
        <div class="overview-box-icon"><i class="pi pi-user"></i></div>
        <div class="overview-box-title">{{ patients.name[lang] }}</div>
        <div class="overview-box-count">{{ patients.value }}</div>
      </div>
    </div>
  </div>

  <div class="col-12 lg:col-6">
    <div class="card flex flex-column align-items-center">
      <h5 class="text-left w-full">{{ patients.name[lang] }} / {{ subscripers.name[lang] }}</h5>
      <p-chart
        type="pie"
        [data]="pieData"
        [options]="pieOptions"
        [style]="{ width: '50%' }"
      ></p-chart>
    </div>
  </div>
  <div class="col-12 lg:col-6">
    <div class="card flex flex-column align-items-center">
      <h5 class="text-left w-full">{{ patients.name[lang] }} / {{ subscripers.name[lang] }}</h5>
      <p-chart
        type="doughnut"
        [data]="pieData"
        [options]="pieOptions"
        [style]="{ width: '50%' }"
      ></p-chart>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppBreadcrumbService } from '../../modules/shared/services/app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../modules/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/modules/shared/services/localstorage.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnDestroy, OnInit {
  subscription: Subscription;
  translateSubscription: Subscription;
  items: MenuItem[];
  email: string;

  constructor(
    public breadcrumbService: AppBreadcrumbService,
    private authservice: AuthService,
    public translate: TranslateService,
    private localStorage: LocalStorageService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
    });
  }

  onLogout() {
    this.authservice.logout();
  }

  ngOnInit() {
    this.email = this.localStorage.getEmail();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

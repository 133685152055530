import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NotifyService } from '../services/notify.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  notifyService: NotifyService;
  constructor(private authService: AuthService, private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!this.notifyService) {
          this.notifyService = this.injector.get(NotifyService);
        }
        switch (error.status) {
          case 401:
            this.authService.logout();
            break;
        }
        this.notifyService.error(error.error.message);
        return throwError(() => error);
      })
    );
  }
}

<div class="layout-breadcrumb">
  <ul>
    <li>
      <a routerLink="dashboard"><i class="pi pi-home"></i></a>
    </li>
    <li>/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
      <li>
        <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{ item.label | translate }}</a>
        <ng-container *ngIf="!item.routerLink">{{ item.label | translate }}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>

  <div class="layout-breadcrumb-options">
    <a style="cursor: pointer" (click)="onLogout()" title="Logout">
      <i class="pi pi-power-off"></i>
    </a>
  </div>
  <ul style="font-size: large" class="layout-breadcrumb-options">
    <li>{{ email }}</li>
  </ul>
</div>

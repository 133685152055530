import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class LangService {
  private defaultLang = 'en';

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.translateService.addLangs(['en', 'ar']);
    this.translateService.setDefaultLang(this.defaultLang);
    this.translateService.use(this.getCurrentLang());
  }

  getCurrentLang(): string {
    return this.localStorageService.getLang() ?? this.defaultLang;
  }

  setCurrentLang(lang: string) {
    this.localStorageService.setLang(lang);
    this.translateService.use(lang);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReportsService {
  urlPatients = environment.urlDomain + 'report/patients';
  urlSubscripers = environment.urlDomain + 'report/subscripers';
  urlProviders = environment.urlDomain + 'report/providers';
  urlDoctors = environment.urlDomain + 'report/doctors';

  constructor(private http: HttpClient) {}

  getPatients() {
    return this.http.get<{
      message: string;
      status: boolean;
      data: number;
    }>(this.urlPatients);
  }

  getSubscripers() {
    return this.http.get<{
      message: string;
      status: boolean;
      data: number;
    }>(this.urlSubscripers);
  }

  getProviders() {
    return this.http.get<{
      message: string;
      status: boolean;
      data: number;
    }>(this.urlProviders);
  }

  getDoctors() {
    return this.http.get<{
      message: string;
      status: boolean;
      data: number;
    }>(this.urlDoctors);
  }
}

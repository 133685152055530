<div class="login-body">
  <div class="login-panel p-fluid">
    <div class="login-panel-header">
      <img src="assets/layout/images/logo-slim.png" />
    </div>
    <div class="login-panel-content">
      <form #form="ngForm" (keyup.enter)="submit(form)">
        <div class="grid">
          <div class="col-12">
            <h1>Reset Password</h1>
          </div>
          <div class="col-12">
            <div class="field">
              <label for="email">Email</label>
              <input
                [pattern]="validEmail"
                pInputText
                id="email"
                name="email"
                [(ngModel)]="email"
                required
                autofocus
                type="email"
                [ngClass]="{
                  'ng-invalid ng-dirty': submitted && (!email || !email.match(validEmail))
                }"
              />
            </div>
            <small *ngIf="submitted" class="p-error">
              <span *ngIf="!email">Email is required</span>
              <span *ngIf="email && !email.match(validEmail)">Email is invalid</span>
            </small>
          </div>
          <div class="col-12">
            <button
              pButton
              [loading]="isLoading"
              type="button"
              label="submit"
              icon="pi pi-check"
              (click)="submit(form)"
            ></button>
          </div>
          <div class="col-12" *ngIf="submitted">
            <small class="p-error"></small>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="login-body">
  <div class="login-panel p-fluid">
    <div class="login-panel-header">
      <img src="assets/layout/images/logo-slim.png" />
    </div>
    <div class="login-panel-content">
      <form [formGroup]="loginFormGroup" (keyup.enter)="onSubmit()">
        <div class="grid">
          <div class="col-12">
            <h1>Sign-in to Espitalia</h1>
          </div>

          <div class="col-12">
            <span>
              <label>Email</label>
              <input type="text" pInputText formControlName="email" />
            </span>
            <small
              *ngIf="loginForm.email.invalid && loginFormGroup.controls.email.touched"
              class="p-error"
            >
              <span *ngIf="loginForm.email.errors.required">email is required</span>
              <span *ngIf="loginForm.email.errors.email">email is invalid</span>
            </small>
          </div>
          <div class="col-12">
            <span>
              <label>Password</label>
              <input type="password" pInputText formControlName="password" />
            </span>
            <small
              *ngIf="loginForm.password.invalid && loginFormGroup.controls.password.touched"
              class="p-error"
            >
              password is required
            </small>
          </div>
          <div class="col-12">
            <button
              pButton
              [loading]="isLoading"
              type="button"
              label="Sign In"
              icon="pi pi-user"
              (click)="onSubmit()"
            ></button>
          </div>
          <div class="col-12" *ngIf="isSubmitted && authError">
            <small class="p-error"></small>
          </div>
        </div>
      </form>
      <div class="col-12">
        <a [routerLink]="['/reset']">Forget Password ?</a>
      </div>
    </div>
  </div>
</div>

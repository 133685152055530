<ng-container>
  <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text">
    {{ item.label | translate }}
    <i
      *ngIf="!appMain.sidebarActive && config.layoutMode !== 'static' && !appMain.isMobile()"
      class="pi pi-ellipsis-h"
    ></i>
  </div>
  <a
    [attr.href]="item.url"
    (click)="itemClick($event)"
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    (mouseenter)="onMouseEnter()"
    (keydown.enter)="itemClick($event)"
    [attr.target]="item.target"
    [attr.tabindex]="0"
    [ngClass]="item.class"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="menuitem-text">{{ item.label | translate }}</span>
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
  </a>
  <a
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    *ngIf="item.routerLink && !item.items && item.visible !== false"
    [routerLink]="item.routerLink"
    routerLinkActive="active-menuitem-routerlink"
    [ngClass]="item.class"
    pRipple
    [routerLinkActiveOptions]="{ exact: !item.preventExact }"
    [attr.target]="item.target"
    [attr.tabindex]="0"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="menuitem-text">{{ item.label | translate }}</span>
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"
      >keyboard_arrow_down</i
    >
    <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
  </a>
  <ul
    *ngIf="(item.items || active) && item.visible !== false"
    [@children]="
      appMain.isHorizontal() && !appMain.isMobile() && root
        ? active
          ? 'visible'
          : 'hidden'
        : root
        ? 'visible'
        : active
        ? 'visibleAnimated'
        : 'hiddenAnimated'
    "
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    </ng-template>
  </ul>
</ng-container>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppBreadcrumbService } from '../../modules/shared/services/app.breadcrumb.service';
import { AppConfig } from '../../modules/shared/models/appconfig';
import { ConfigService } from '../../modules/shared/services/app.config.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReportsService } from 'src/app/modules/shared/services/reports.service';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  config: AppConfig;
  lang: string;
  destroyed = new Subject<void>();
  pieData: any;
  pieOptions: any;
  subscripers = { name: { en: 'Subscribers', ar: 'مشتركين' }, value: 0 };
  patients = { name: { en: 'Patients', ar: 'مستخدمين' }, value: 0 };
  providers = { name: { en: 'Providers', ar: 'مزودوا الخدمة' }, value: 0 };
  doctors = { name: { en: 'doctors', ar: 'الاطباء' }, value: 0 };
  dataChart = [];
  dataLabel = [];

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private reportsService: ReportsService,
    public configService: ConfigService,
    public translate: TranslateService
  ) {
    this.breadcrumbService.setItems([{ label: 'breadcrumb.dashboard', routerLink: ['dashboard'] }]);
    this.config = this.configService.config;
    this.configService.configUpdate$.pipe(takeUntil(this.destroyed)).subscribe((config) => {
      this.config = config;
    });
    this.lang = this.translate.currentLang;
  }

  ngOnInit() {
    this.dataLabel = [this.patients.name[this.lang], this.subscripers.name[this.lang]];
    this.pieData = {
      labels: this.dataLabel,
      datasets: [
        {
          data: this.dataChart,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };

    this.translate.onLangChange
      .pipe(takeUntil(this.destroyed))
      .subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
        this.breadcrumbService.setItems([{ label: 'breadcrumb.dashboard' }]);
        this.dataLabel = [this.patients.name[this.lang], this.subscripers.name[this.lang]];
        this.pieData.labels = this.dataLabel;
      });
    this.getPatientsData();
    this.getSubscripersData();
    this.getProvidersData();
    this.getDoctorsData();
  }

  setPieData() {}

  getPatientsData() {
    this.reportsService.getPatients().subscribe((res) => {
      this.patients.value = res.data;
      this.pieData.datasets[0].data[0] = this.patients.value;
      this.breadcrumbService.setItems([
        { label: 'breadcrumb.dashboard', routerLink: ['dashboard'] },
      ]);
      this.pieData = JSON.parse(JSON.stringify(this.pieData));
    });
  }

  getProvidersData() {
    this.reportsService.getProviders().subscribe((res) => {
      this.providers.value = res.data;
    });
  }

  getDoctorsData() {
    this.reportsService.getDoctors().subscribe((res) => {
      this.doctors.value = res.data;
    });
  }

  getSubscripersData() {
    this.reportsService.getSubscripers().subscribe((res) => {
      this.subscripers.value = res.data;
      this.pieData.datasets[0].data[1] = this.subscripers.value;
      this.pieData = JSON.parse(JSON.stringify(this.pieData));
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

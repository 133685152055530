import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AppMainComponent } from './layout/app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './modules/auth/login/app.login.component';
import { AuthGaurdService } from './modules/shared/guards/auth.guard';
import { AppResetComponent } from './modules/auth/reset/app.reset.component';
import { AppResetEmailComponent } from './modules/auth/reset-email/app.reset-email.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          canActivate: [AuthGaurdService],
          children: [
            {
              path: 'dashboard',
              component: DashboardComponent,
              canActivate: [AuthGaurdService],
            },
            {
              path: 'specialities',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/specialities/specialities.module').then(
                  (m) => m.SpecialitiesModule
                ),
            },
            {
              path: 'subspecialities',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/sub-specialities/sub-specialities.module').then(
                  (m) => m.SubSpecialitiesModule
                ),
            },
            {
              path: 'providers',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/providers/providers.module').then((m) => m.ProvidersModule),
            },
            {
              path: 'roles',
              canActivate: [AuthGaurdService],
              loadChildren: () => import('./modules/roles/roles.module').then((m) => m.RolesModule),
            },
            {
              path: 'contacts-provider',
              loadChildren: () =>
                import('./modules/contacts-provider/contacts-provider.module').then(
                  (m) => m.ContactsProviderModule
                ),
            },
            {
              path: 'users',
              canActivate: [AuthGaurdService],
              loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
            },
            {
              path: 'employees',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/employees/employees.module').then((m) => m.EmployeesModule),
            },
            {
              path: 'governorates',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/governorates/governorates.module').then(
                  (m) => m.GovernoratesModule
                ),
            },
            {
              path: 'areas',
              canActivate: [AuthGaurdService],
              loadChildren: () => import('./modules/areas/areas.module').then((m) => m.AreasModule),
            },
            {
              path: 'codes',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/discount-code/discount-code.module').then(
                  (m) => m.DiscountCodeModule
                ),
            },
            {
              path: 'diseases',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/diseases/diseases.module').then((m) => m.DiseasesModule),
            },
            {
              path: 'contacts',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/contacts/contacts.module').then((m) => m.ContactsModule),
            },
            {
              path: 'doctors',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/doctors/doctors.module').then((m) => m.DoctorsModule),
            },
            {
              path: 'plans',
              canActivate: [AuthGaurdService],
              loadChildren: () => import('./modules/plans/plans.module').then((m) => m.PlansModule),
            },
            {
              path: 'subscriptions',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/subscriptions/subscriptions.module').then(
                  (m) => m.SubscriptionsModule
                ),
            },
            {
              path: 'promocodes',
              canActivate: [AuthGaurdService],
              loadChildren: () =>
                import('./modules/promo-codes/promo-codes.module').then((m) => m.PromoCodesModule),
            },
          ],
        },

        { path: 'error', component: AppErrorComponent },
        { path: 'access', component: AppAccessdeniedComponent },
        { path: 'notfound', component: AppNotfoundComponent },
        {
          path: 'login',
          component: AppLoginComponent,
          canActivate: [AuthGaurdService],
        },
        {
          path: 'reset/:id',
          component: AppResetComponent,
        },
        {
          path: 'reset',
          component: AppResetEmailComponent,
          canActivate: [AuthGaurdService],
        },
        { path: '**', redirectTo: '/notfound' },
      ],
      { scrollPositionRestoration: 'enabled' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

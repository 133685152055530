import { HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/localstorage.service';
import { AppGovsFilterService } from '../services/app.govs-filter.service';

@Injectable({
  providedIn: 'root',
})
export class GovsFilterInterceptor implements HttpInterceptor {
  constructor(
    private appGovsFilter: AppGovsFilterService,
    private localStorage: LocalStorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.method === 'GET') {
      const govs = this.localStorage.getGovs();
      if (govs) {
        request = request.clone({
          setParams: {
            'filter[government]': govs ?? null,
          },
        });
      }
    }
    return next.handle(request);
  }
}

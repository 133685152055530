import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from '../app.main.component';
import { AuthService } from '../../modules/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/modules/shared/services/lang.service';
import { ConfigService } from 'src/app/modules/shared/services/app.config.service';
import { AppConfig } from 'src/app/modules/shared/models/appconfig';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Governorate } from 'src/app/modules/shared/models/governorate';
import { Router } from '@angular/router';
import { AppGovsFilterService } from 'src/app/modules/shared/services/app.govs-filter.service';
import { ModeService } from 'src/app/modules/shared/services/mode.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  currentLang: string;
  currentMode: 'light' | 'dark';
  config: AppConfig;
  configSubscription: Subscription;
  governorates: Partial<Governorate>[];
  selectedGovs: Partial<Governorate>[];
  destroyed = new Subject<void>();

  constructor(
    private authservice: AuthService,
    private langService: LangService,
    private modeService: ModeService,
    public translate: TranslateService,
    public appMain: AppMainComponent,
    private configService: ConfigService,
    private appGovsFilter: AppGovsFilterService,
    private router: Router
  ) {
    this.governorates = appGovsFilter.getAllGovs();
    this.config = this.configService.config;
    this.configSubscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
    this.appGovsFilter.govsHandler.pipe(takeUntil(this.destroyed)).subscribe((response) => {
      this.selectedGovs = response;
    });
    this.appGovsFilter.allgovsHandler.pipe(takeUntil(this.destroyed)).subscribe((response) => {
      this.governorates = response;
    });
  }

  ngOnInit(): void {
    this.currentLang = this.langService.getCurrentLang();
    this.currentMode = this.modeService.getCurrentMode();

    this.updateMode();
  }

  changeLayoutMode() {
    this.modeService.setCurrentMode(
      this.modeService.getCurrentMode() === 'light' ? 'dark' : 'light'
    );
    this.currentMode = this.modeService.getCurrentMode() === 'light' ? 'light' : 'dark';
    this.config.layoutColor = this.currentMode;
    this.config.darkMenu = this.currentMode === 'light' ? false : true;
    this.updateMode();
  }

  updateMode() {
    const themeLink = document.getElementById('theme-css');
    const urlTokens = themeLink.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = 'theme-' + this.config.layoutColor + '.css';
    const newURL = urlTokens.join('/');

    this.replaceLink(themeLink, newURL);
    this.configService.updateConfig({
      ...this.config,
      ...{ dark: this.currentMode.indexOf('light') === -1 },
    });
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  changeLang() {
    this.langService.setCurrentLang(this.langService.getCurrentLang() === 'ar' ? 'en' : 'ar');
    this.configService.updateConfig({
      ...this.config,
      isRTL: this.langService.getCurrentLang() === 'ar' ? true : false,
    });
    this.currentLang = this.langService.getCurrentLang();
  }

  changeGovs(event: Partial<Governorate>[]) {
    this.appGovsFilter.changeGovs(event);
    this.addGovFilter();
  }

  addGovFilter() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  onLogout() {
    this.authservice.logout();
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class NotifyService {
  constructor(private messageService: MessageService, private translateService: TranslateService) {}
  success(successMessage: string) {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('commons.success'),
      detail: successMessage,
      life: 3000,
    });
  }
  error(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('commons.error'),
      detail: errorMessage,
      life: 3000,
    });
  }
  info(infoMessage: string) {
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('commons.info'),
      detail: infoMessage,
      life: 3000,
    });
  }
  warn(warnMessage: string) {
    this.messageService.add({
      severity: 'warn',
      summary: this.translateService.instant('commons.warn'),
      detail: warnMessage,
      life: 3000,
    });
  }
}

import { Injectable } from '@angular/core';
import { Params } from '../models/params';

@Injectable()
export class FilterService {
  constructor() {}

  reviewParams(params: any, paramsType: string[]): [boolean, Partial<Params>] {
    let newParams = {};
    let valid = true;
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        if (paramsType.includes(key) && params[key]) {
          newParams[key] = params[key];
        } else {
          valid = false;
        }
      }
    }
    return [
      +params['page'] > 0 && PERPAGEOPTIONS.includes(+params['per_page']) && valid,
      {
        ...newParams,
        page: +params['page'] > 0 ? +params['page'] : 1,
        per_page: PERPAGEOPTIONS.includes(+params['per_page']) ? +params['per_page'] : 50,
      },
    ];
  }
}

export const PERPAGEOPTIONS = [10, 20, 50];
